.sign-in-detail {
  color: $white;
  height: 100vh;
}
.sign-in-page {
  overflow: hidden;
  height: 100vh;
  background: var(--#{$variable-prefix}primary);
  background: rgba($primary, 80);
  background: -moz-linear-gradient(-45deg, rgba($primary, 80) 0%, #182039 100%);
  background: -webkit-linear-gradient(
    -45deg,
    rgba($primary, 80) 0%,
    #182039 100%
  );
  background: "#0B163E";
  .bg-black {
    background-color: #0f0f0f !important;
    color: $body-color;
  }
  .bg-white {
    background-color: $white !important;
    color: $body-color;
  }
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(var(--bs-primary-rgb),.8)', endColorstr='var(--bs-primary)', GradientType=1);
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  margin: 0rem auto;
  padding: 0rem auto;
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.sign-in-logo {
  display: inline-block;
  width: 100%;
  img {
    height: 3.125rem;
  }
}
.sign-in-from {
  padding: 0 3.75rem;
}
.sign-info {
  border-top: 1px solid $border-color;
  margin-top: 1.875rem;
  padding-top: 1.25rem;
}
.iq-social-media {
  margin: 0;
  padding: 0;
  float: right;
  li {
    list-style: none;
    float: left;
    margin-right: 0.625rem;
    &:last-child {
      margin-right: 0;
    }
    a {
      height: 1.875rem;
      width: 1.875rem;
      text-align: center;
      font-size: 1.125rem;
      line-height: 1.875rem;
      display: inline-block;
      background: rgba(var(--bs-primary-rgb), 0.2);
      color: var(--bs-primary) !important;
      @if $enable-rounded == true {
        @include radius-mixin($border-radius);
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
#container-inside {
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0px auto;
  padding: 0px auto;
  overflow: visible;
}
#circle-small {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0s;
  position: absolute;
  top: 12.5rem;
  left: -9.375rem;
  background: #0b163e;
  width: 18.75rem;
  height: 18.75rem;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius-pill);
  }
  opacity: 1;
}
#circle-medium {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0.3s;
  position: absolute;
  top: 3.125rem;
  left: -18.75rem;
  background: #0b163e;
  width: 37.5rem;
  height: 37.5rem;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius-pill);
  }
  opacity: 0.7;
}
#circle-large {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0.6s;
  position: absolute;
  top: -6.25rem;
  left: -28.125rem;
  background: #0b163e;
  width: 56.25rem;
  height: 56.25rem;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius-pill);
  }
  opacity: 0.5;
}
#circle-xlarge {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 0.9s;
  position: absolute;
  top: -15.625rem;
  left: -37.5rem;
  background: #0b163e;
  width: 75rem;
  height: 75rem;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius-pill);
  }
  opacity: 0.35;
}
#circle-xxlarge {
  -webkit-animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation: circle-small-scale 3s ease-in-out infinite alternate;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
  animation-delay: 1.2s;
  position: absolute;
  top: -25rem;
  left: -46.875rem;
  background: #0b163e;
  width: 93.75rem;
  height: 93.75rem;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius-pill);
  }
  opacity: 0.2;
}

@include media-breakpoint-down(lg) {
  .sign-in-detail {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@include media-breakpoint-down(md) {
  .sign-in-detail {
    padding: 3.125rem 1.25rem;
  }
}

@-webkit-keyframes circle-small-scale {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
  }
}
@keyframes circle-small-scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Animation pour simuler l'effet néon qui s'allume et s'éteint */
@keyframes neonGlow {
  0%,
  100% {
    text-shadow: 0 0 5px rgb(41, 0, 247), 0 0 10px rgb(41, 0, 247),
      0 0 20px rgb(41, 0, 247), 0 0 40px rgb(41, 0, 247),
      0 0 80px rgb(41, 0, 247);
    opacity: 1;
  }
  50% {
    text-shadow: 0 0 2px rgb(41, 0, 247), 0 0 5px rgb(41, 0, 247),
      0 0 10px rgb(41, 0, 247), 0 0 20px rgb(41, 0, 247); /* Réduction de l'intensité à mi-parcours */
    opacity: 0.8;
  }
}

/* Animation pour simuler l'effet néon qui s'allume et s'éteint */
@keyframes neonGlow {
  0%,
  100% {
    text-shadow: 0 0 5px rgb(41, 0, 247), 0 0 10px rgb(41, 0, 247),
      0 0 20px rgb(41, 0, 247), 0 0 30px rgb(41, 0, 247);
    opacity: 1;
  }
  50% {
    text-shadow: 0 0 10px rgb(41, 0, 247), 0 0 20px rgb(41, 0, 247),
      0 0 30px rgb(41, 0, 247), 0 0 40px rgb(41, 0, 247);
    opacity: 0.5;
  }
}

.arrows {
  width: 60px;
  height: 72px;
}

@media (max-height: 668px) {
  .arrows {
    width: 60px;
    height: 72px;
    transform: scale(0.5);
  }

  .center-text-arrow {
    font-size: 12px !important;
    margin-left: -10px !important;
    margin-bottom: -18px !important;
  }
}
.arrows path {
  stroke: #2994d1;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}

.container-scroll {
  position: absolute;
  bottom: 5%;
  left: 18%;
  z-index: 100;
}

.center-text-arrow {
  font-size: 16px;
  margin-bottom: 8px;
  color: white; /* Le texte est en blanc */
  text-shadow: 0 0 5px rgb(41, 0, 247),
    /* Ombres néon bleu */ 0 0 10px rgb(41, 0, 247), 0 0 20px rgb(41, 0, 247),
    0 0 40px rgb(41, 0, 247), 0 0 80px rgb(41, 0, 247); /* Plus large pour effet néon intense */
  animation: neonGlow 1.5s ease-in-out infinite;
  cursor: pointer;
}

.padding-24 {
  padding-left: 24px;
  padding-right: 24px;
}

@media (max-width: 960px) {
  .container-scroll {
    position: absolute;
    bottom: 1%;
    left: 40%;
    z-index: 100;
  }
}

@media (max-width: 991px) {
  .max-width-large-mobile {
    max-width: 90%;
  }
}
@media (max-width: 960px) {
  .max-width-large-mobile {
    max-width: "-webkit-fill-available";
  }
}
