section {
  &.sign-in-page {
    height: 100vh;
    background: var(--#{$variable-prefix}primary);
    background: rgba($primary, 80);
    background: -moz-linear-gradient(
      -45deg,
      rgba($primary, 80) 0%,
      #182039 100%
    );
    background: -webkit-linear-gradient(
      -45deg,
      rgba($primary, 80) 0%,
      #182039 100%
    );
    background: "#0B163E";

    .bg-white {
      background-color: $card-bg !important;
      color: $body-color;
    }
    .sign-info {
      border-color: $border-color;
    }
  }
}
#circle-small {
  background: #0b163e;
}
#circle-medium {
  background: #0b163e;
}
#circle-large {
  background: #0b163e;
}
#circle-xlarge {
  background: #0b163e;
}
#circle-xxlarge {
  background: #0b163e;
}
