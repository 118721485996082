.chat-body {
    height: 100%;
    min-height: calc(100vh - 9.7rem);
    max-height: calc(100vh - 9.7rem);
    overflow-y: scroll;
    object-fit: cover;
    // background-image: url(../images/10.svg);
    // background-repeat: repeat;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.sidebar.sidebar-chat.sidebar-base{
    +.main-content {
        margin-left: 22rem;
    }
}

@include media-breakpoint-down(xl) {
    .sidebar.sidebar-chat.sidebar-base{
        +.main-content {
            margin-left: 0;
        }
    }
}